// Styles
import "./styles/index.scss";
// React
import React, { StrictMode, useEffect, useMemo } from "react";
// Router
import { DefaultRouter } from "@routes/router/Default";
// Redux
import * as Store from "@redux/rtk/";
import { ClientGroupMember } from "@redux/rtk/types";
// API
import { API } from "@api/index";
// Utils
import { ErrorBoundary } from "@advicefront/fe-infra-error-and-logging";
import { GlobalState } from "@advicefront/fe-infra-global-state";
import { UseAuthenticatedProps } from "@advicefront/fe-infra-auth";
import { lang } from "@lang/index";
// Context
import { AppOptionsContext, AppOptionsContextProps } from "@context/app-options";
// Components
import { AfRoot } from "@advicefront/ds-base";
import { AfSnackbar } from "@advicefront/ds-snackbar";
import { ErrorLayout } from "@layouts/error";
// Config
import { ROOT_ELEMENT_ID } from "./config-client";

const AppContent = (): React.ReactElement => {
  useEffect(() => {
    // Setup base api options
    API.configure({
      goals: {
        basePath: GlobalState.get<string>("goals-api-url", {
          requireDefined: true,
        }),
      },
    });
  }, []);

  const globalAppOptions: AppOptionsContextProps = useMemo(
    (): AppOptionsContextProps => ({
      goalsApiHost: GlobalState.get<string | undefined>("goals-api-url"),
      routerBaseName: GlobalState.get<string | undefined>("goals-router-basename"),
      hasOptimalIntegration:
        GlobalState.get<string>("goals-firm-features-optimal", {
          requireDefined: true,
        }) === "true",
      getClientGroupId: GlobalState.get<() => Promise<string | null>>("get-client-group-id", {
        requireDefined: true,
      }),
      getAuthToken: GlobalState.get<UseAuthenticatedProps["getAuthToken"]>("get-auth-token", {
        requireDefined: true,
      }),
      clientGroupMembers: GlobalState.get<() => Promise<ClientGroupMember[]>>(
        "get-client-group-info",
        {
          requireDefined: true,
        }
      ),
    }),
    []
  );
  return (
    <AfRoot cssScope={`${ROOT_ELEMENT_ID}-scope`} classNamesPrefix={`${ROOT_ELEMENT_ID}-component`}>
      <AfSnackbar>
        <Store.Provider>
          <AppOptionsContext.Provider value={globalAppOptions}>
            <DefaultRouter />
          </AppOptionsContext.Provider>
        </Store.Provider>
      </AfSnackbar>
    </AfRoot>
  );
};

export const App = (): React.ReactElement => (
  <StrictMode>
    <ErrorBoundary
      Component={(err): React.ReactElement => (
        <ErrorLayout
          title={lang("ERROR_PAGE_GENERIC_TITLE")}
          description={
            <>
              {lang("ERROR_PAGE_GENERIC_DESCRIPTION")}
              <br />
              <br />
              <u>{err.message}</u>
            </>
          }
        />
      )}
    >
      <AppContent />
    </ErrorBoundary>
  </StrictMode>
);
