// React
import React, { useCallback, useEffect } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useClientGroup } from "@hooks/client-group";
import { useIsLoading } from "@hooks/is-loading";
// Views
import { AccountsTable } from "@views/objectives/accounts/accounts-table";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfSpinner } from "@advicefront/ds-spinner";
import { EmptyData } from "@components/empty-data";
import { Section } from "@components/section";

export const Accounts = (): React.ReactElement => {
  // Redux
  const dispatch = Store.useDispatch();
  const { accounts, portfolios } = Store.useSelector();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();

  // Url params
  const [, setUrlParams] = useSearchParams();

  // Loading
  const isLoading = useIsLoading(accounts, portfolios);

  // Handle create account
  const handleCreateAccount = useCallback(
    (): void =>
      setUrlParams({
        action: FormActions.create,
        type: FormTypes.account,
      }),
    [setUrlParams]
  );

  // Fetch accounts
  useEffect(() => {
    if (!clientGroupId || !authToken || accounts.data) return;
    // Fetch accounts
    void dispatch(
      Store.Accounts.fetch({
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  }, [dispatch, clientGroupId, authToken, accounts.data]);

  // Fetch portfolios
  useEffect(() => {
    if (portfolios.data || !authToken) return;
    void dispatch(Store.Portfolios.fetch(authToken));
  }, [dispatch, authToken, portfolios.data]);

  return (
    <Section
      title={lang("ACCOUNTS_TITLE")}
      action={
        !isLoading &&
        !!accounts.data?.length && (
          <AfButton
            skin="primary"
            icon={<AfIcon name="basic-add-circle" />}
            onClick={handleCreateAccount}
          >
            {lang("CREATE_ACCOUNT_ACTION")}
          </AfButton>
        )
      }
    >
      {isLoading && <AfSpinner className="spinner-centered" />}
      {!isLoading && (
        <>
          {!!accounts.data?.length && <AccountsTable />}
          {!accounts.data?.length && (
            <EmptyData
              title={lang("NO_ACCOUNTS_TITLE")}
              description={lang("NO_ACCOUNTS_DESCRIPTION")}
              action={{
                title: lang("CREATE_ACCOUNT_ACTION"),
                handler: handleCreateAccount,
              }}
            />
          )}
        </>
      )}
    </Section>
  );
};
