/* eslint-disable @typescript-eslint/consistent-type-assertions */
// Types
import {
  CreateNeedGoalDto,
  NeedType,
  ResponseNeedGoalDto,
  UpdateNeedGoalDto,
} from "@advicefront/goals-client-axios";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import { isCreateNeedGoalData, isUpdateNeedGoalData } from "@utils/type-guards/goals/need-goal";

/**
 * Transform function from need goal state data to Form Schema type
 * @param data - need goal data from Store
 * @returns need goal data in Form Schema object or undefined
 */

export const needGoalDataToForm = (
  data: ResponseNeedGoalDto
): FormSchemaRenderer.ComputedValuesState => ({
  ["name"]: data.name,
  ["need-type"]: data.needType,
  ["requirements"]: data.requirements || undefined,
  ["linked-protections-incremental"]: data.linkedProtections?.map((protection) => protection._id),
});

/**
 * Transform function from Form Schema inputs to Need Goal Data type for creating Need Goal
 * @param data - need goal data from Form Schema inputs
 * @param clientGroupId - clientGroupId
 * @returns Need goal data in create need goal type
 */

export const needGoalFormToCreateNeedGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | CreateNeedGoalDto,
  clientGroupId: string
): CreateNeedGoalDto | undefined => {
  if (!data) return;
  if (isCreateNeedGoalData(data)) {
    return data;
  }
  if (!isCreateNeedGoalData(data)) {
    return {
      clientGroupId: clientGroupId,
      name: data["name"] as string,
      requirements: data["requirements"] as string,
      needType: data["need-type"] as NeedType,
      linkedProtections: data["linked-protections-incremental"] as string[],
    };
  }
};

/**
 * Transform function from Form Schema inputs to Objective Goal Data type for creating Objective Goal
 * @param data - need goal data from Form Schema inputs
 * @returns Need goal data in update need goal type
 */

export const needGoalFormToUpdateNeedGoalData = (
  data: FormSchemaRenderer.ComputedValuesState | UpdateNeedGoalDto
): UpdateNeedGoalDto | undefined => {
  if (!data) return;
  if (isUpdateNeedGoalData(data)) {
    return data;
  }
  if (!isUpdateNeedGoalData(data)) {
    return {
      name: data["name"] as string,
      requirements: data["requirements"] as string,
      needType: data["need-type"] as NeedType,
      linkedProtections: data["linked-protections-incremental"] as string[],
    };
  }
};
