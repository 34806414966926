// React
import { useMemo } from "react";
// Store
import { useAuthHandler } from "@hooks/auth-handler";

/**
 * UseIsLoading
 * Hook to check if some loader is loading
 * By default it will always check the auth token
 * @returns boolean to check if is loading
 * @param modules - rtk module to check on
 */
export const useIsLoading = <T extends Array<{ loading: number }>>(...modules: T): boolean => {
  const { loading } = useAuthHandler();
  // Check auth and loaders
  return useMemo(() => loading || modules.some(({ loading }) => !!loading), [loading, modules]);
};
