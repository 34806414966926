/**
 * Configurations to apply during runtime
 */

import packageJson from "../package.json";

/**
 * Get application's root html element ID from package.json name
 * Gets name after last slash for scoped application names like "\@scope-name/package-name"
 */

export const ROOT_ELEMENT_ID = ((): string => {
  const id = packageJson.name.split("/").pop();
  if (!id) throw new Error("Unable to compute id from package name");
  return id;
})();
