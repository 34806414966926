// React
import React from "react";
// Translations
import { lang } from "@lang/index";
// Layouts
import { DefaultLayout } from "@layouts/default";

export interface ErrorViewProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const ErrorView = ({
  children = <p>{lang("ERROR_PAGE_NOT_FOUND")}</p>,
}: ErrorViewProps): React.ReactElement => (
  <DefaultLayout>
    <h2>{lang("ERROR_SOMETHING_MISSING")}</h2>
    <p>{children}</p>
  </DefaultLayout>
);
