// React
import React, { useCallback, useEffect, useRef, useState } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk";
// Form Schema
import { FormSchema, FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
import { FormRenderer } from "@components/form-schema-renderer";
// Form Module
import { FormModule } from "@components/modals/modules";
// Types
import {
  ComputedValuesState,
  FormSchemaRendererProps,
  FormSchemaRendererRef,
} from "@advicefront/fe-infra-form-schema/dist/src/renderer";
import { FormRendererOptionsProps } from "@components/form-schema-renderer/types";
// Utils
import { formatValues, unFormatValues } from "@components/form-schema-renderer/utils/formatting";
import { isEmpty } from "@utils/is-empty";
import { isEqual } from "@utils/is-equal";
// Constants
import { CURRENT_MONTH, CURRENT_YEAR, MONTHS_VALUES, YEARS } from "@constants/dates";
import { FORM_CHANGED_INITIAL_STATE, FormActions, FormTypes } from "@constants/index";
// Translations
import { getTranslationEntry, lang } from "@lang/index";
// Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useBeforeUnload } from "@hooks/before-unload";
import { useClientGroup } from "@hooks/client-group";
// Components
import { ConfirmModal, ConfirmModalProps } from "@components/modals/confirm-modal";
import { AfModal } from "@advicefront/ds-modal";
// Context
import { useAppOptions } from "@context/app-options";

// Props
interface FormModalProps {
  action: FormActions;
  type: FormTypes;
  id?: string;
}

export const FormModal = ({ action, type, id = "" }: FormModalProps): React.ReactElement => {
  // Redux
  const { portfolios, accounts, protections } = Store.useSelector();

  //Context
  const { hasOptimalIntegration } = useAppOptions();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();

  // Url params
  const [, setUrlParams] = useSearchParams();

  // Form steps
  const [formStep, setFormStep] = useState<FormRendererOptionsProps["formStep"]>(undefined);

  // Form schema reference
  const formRef = useRef<HTMLFormElement>(null);
  const formRendererRef = useRef<FormSchemaRendererRef>();

  // Current form module
  const formModule = FormModule(action, type, id);

  // Current form changed value reference
  const formChanged = useRef(FORM_CHANGED_INITIAL_STATE);

  // Current values reference used for render components
  const currentValues = useRef<ComputedValuesState | undefined>(undefined);

  // Previous values reference used for confirm modal
  const previousValues = useRef<ComputedValuesState | undefined>(undefined);

  // Confirm modal used when a field needs to be confirmed or when closing the form without saving
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps | undefined>(undefined);

  /**
   * Set Form Values
   * Update form render, current and previous form values
   */
  const setFormValues = useCallback(
    (values: ComputedValuesState | undefined): void => {
      // Stop if no values
      if (!values) return undefined;
      // Raw all form values
      const rawValues = unFormatValues(values);
      // Set form changed if both values are different
      formChanged.current =
        action === FormActions.create
          ? Object.values(rawValues).some((value) => !isEmpty(value))
          : !isEqual(formModule.initialValues, rawValues);
      // Format values
      const formattedValues = formatValues(rawValues);
      // Set form render values
      formRendererRef.current?.setValues(formattedValues);
      // Set current values
      currentValues.current = formattedValues;
      // Set previous values
      previousValues.current = formattedValues;
    },
    [action, formModule.initialValues]
  );

  /**
   * Before Unload
   * Check if form was changed and not saved before reloading the page
   */
  useBeforeUnload((e) => {
    // Stop if form was not changed
    if (!formChanged.current) return;
    // Display browser prompt
    e.preventDefault();
    e.returnValue = lang(
      getTranslationEntry(`CLOSE_CONFIRM_${action.toUpperCase()}_MODAL_DESCRIPTION`)
    );
  });

  /**
   * MultiStep Form
   * Set automatically the first step on a multi step form
   */
  useEffect(() => {
    if (!formStep && FormSchema.isMultiStepForm(formModule.formSchema)) {
      const firstStep = Object.keys(formModule.formSchema.steps)[0];
      setFormStep(firstStep);
    }
  }, [formModule.formSchema, formStep]);

  /**
   * Initial Values
   * Set modal form initial values when opening edit modal
   */
  useEffect(() => {
    // Stop if action isn't edit or no initial values
    if (action !== FormActions.edit || !formModule.initialValues) return;
    // Set form values
    setFormValues(formModule.initialValues);
  }, [action, type, setFormValues, formModule.initialValues, formModule.formSchema]);

  /**
   * Close
   * Callback when closing modal or cancelling
   */
  const handleClose = useCallback((): void => {
    // Confirm closing without saving
    if (formChanged.current) {
      setConfirmModal({
        title: lang("CONFIRM_MODAL_CLOSE_TITLE", formModule.formTitle),
        description: lang(
          getTranslationEntry(`CONFIRM_MODAL_CLOSE_${action.toUpperCase()}_DESCRIPTION`),
          formModule.formTitle
        ),
        cancel: {
          title: lang("CANCEL_ACTION"),
          action: () => setConfirmModal(undefined),
        },
        confirm: {
          title: lang("CLOSE_ACTION"),
          action: () => setUrlParams({}),
        },
      });
      return;
    }
    // Close form modal by default
    setUrlParams({});
  }, [formModule.formTitle, action, setUrlParams]);

  /**
   * Close Automatically
   * On success submission close modal
   */
  useEffect(() => {
    if (formModule.closeModal) {
      // Reset form changed to initial state
      formChanged.current = FORM_CHANGED_INITIAL_STATE;
      // Close modal
      handleClose();
    }
  }, [formModule.closeModal, handleClose]);

  /**
   * Delete
   * Callback when clicking on delete inside the modal
   */
  const handleDelete = useCallback(
    (): void =>
      setUrlParams({
        action: FormActions.delete,
        type,
        id,
        referral: FormActions.edit,
      }),
    [id, type, setUrlParams]
  );

  /**
   * Change
   * Callback when a form value is changed which can also be confirmed
   */
  const handleChange = useCallback<NonNullable<FormSchemaRendererProps["onChange"]>>(
    (e): void => {
      // Check if change need to be confirm
      const confirmChange =
        formModule.handleChange &&
        formModule.handleChange({
          nodeKey: e.field?.nodeKey,
          data: unFormatValues(e.computedValues),
        });
      // Confirm changed field
      if (confirmChange) {
        setConfirmModal({
          title: lang("CONFIRM_MODAL_CHANGE_TITLE", confirmChange.title),
          description: lang("CONFIRM_MODAL_CHANGE_DESCRIPTION", confirmChange.title),
          unlinkMessage: confirmChange.unlinkMessage,
          cancel: {
            title: lang("KEEP_ACTION", confirmChange.title),
            action: () => {
              setFormValues(previousValues.current);
              setConfirmModal(undefined);
            },
          },
          confirm: {
            title: lang("CHANGE_ACTION", confirmChange.title),
            action: () => {
              setFormValues({
                ...e.computedValues,
                ...confirmChange.fieldsToUpdate,
              });
              setConfirmModal(undefined);
            },
          },
        });
        return;
      }
      // Set form values
      setFormValues(e.computedValues);
    },
    [formModule, setFormValues]
  );

  /**
   * Submit
   * Action on create or edit
   */
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    // Prevent default action
    e.preventDefault();
    // Handle exception when action isn't create or edit
    if (![FormActions.create, FormActions.edit].includes(action))
      throw new Error(`Form action not supported - "${action}"`);
    // Handle exception when form values doesn't exist
    if (!formRendererRef.current) throw new Error("Unable to get form values");
    // Set data unformatted for submit
    const data = unFormatValues(formRendererRef.current.getValues());

    // Handle exception when no auth token or client group id
    if (!authToken || !clientGroupId)
      throw new Error("Unable to get authentication or client group");
    // Submit form based on action
    if (action === FormActions.edit) {
      formModule.handleUpdate(data);
    } else {
      formModule.handleCreate(data);
    }
  };

  return (
    <>
      <AfModal spacing="s" closeOnClickOutside={false} onClose={handleClose}>
        <FormSchemaRenderer.FormSchemaRenderer
          onChange={handleChange}
          ref={formRendererRef}
          wrapperRef={formRef}
          schema={formModule.formSchema}
          schemaRenderer={
            new FormRenderer({
              id,
              loading: formModule.loading,
              hasOptimalIntegration: hasOptimalIntegration,
              formRef,
              formRendererRef,
              formStep,
              formType: type,
              formAction: action,
              formChanged,
              formValues: currentValues,
              handleSubmit,
              handleDelete,
              tabNavigation: (step) => setFormStep(step),
              previousStep: (steps, index) => setFormStep(steps[index - 1]),
              nextStep: (steps, index) => setFormStep(steps[index + 1]),
              filterSelectInputOption: ({
                field,
                fieldValue,
                optionValue,
                computedValues,
              }): boolean => {
                // TODO: Refactor task: https://advicefront.atlassian.net/browse/PROD-210

                /**
                 * It will check on each option value of select if the condition is true
                 * if the condition is true, that option will be presented
                 */
                const computedValue =
                  computedValues[field.parentField?.valueKey || field.valueKey || ""];

                const nodeKey = field.parentField?.nodeKey || field.nodeKey;

                // Account
                if (type === FormTypes.account) {
                  if (nodeKey === "portfolioAllocation" && Array.isArray(computedValue)) {
                    const dependencyRiskLevel = computedValues["account-risk-level"];
                    const dependencyPortfolioGroup = computedValues["provider-id"];
                    const allowedPortfoliosIDs: string[] =
                      portfolios?.data
                        ?.find((group) => group.portfolioGroupId === dependencyPortfolioGroup)
                        ?.portfolios?.filter((it) => it.risk === dependencyRiskLevel)
                        .map((it) => it.id) || [];

                    return (
                      optionValue === fieldValue ||
                      (!computedValue.includes(optionValue) &&
                        allowedPortfoliosIDs.includes(optionValue))
                    );
                  }
                }

                // Objective Goal accounts selection
                if (type === FormTypes.objectiveGoal) {
                  if (nodeKey === "linked-accounts-incremental" && Array.isArray(computedValue)) {
                    const dependency = computedValues["risk-level"];
                    const allowedAccountsIDs: string[] =
                      accounts?.data
                        //TODO consider joining filters during FS v2 update
                        ?.filter((it) => it.riskLevel === dependency)
                        //filter only connected to goal account and accounts without goal relation
                        .filter(
                          (it) => it.linkedGoals?.includes(id) || it.linkedGoals?.length === 0
                        )
                        .map((it) => it._id) || [];

                    return (
                      optionValue === fieldValue ||
                      (!computedValue.includes(optionValue) &&
                        allowedAccountsIDs.includes(optionValue))
                    );
                  }

                  /**
                   * Months: Current year should only show months after the current one
                   * Years: Current year will not be displayed if months have already passed
                   * @example Current date: Sep 2022 - min date Oct 2022
                   * so month options should be: Oct, Nov, Dec
                   */
                  if (nodeKey === "target-date-year") {
                    const dependency = computedValues["target-date-month"];
                    const allowedYears =
                      dependency && parseFloat(dependency.toString()) <= CURRENT_MONTH
                        ? YEARS.slice(1)
                        : YEARS;

                    return optionValue === fieldValue || allowedYears.includes(optionValue);
                  }

                  if (nodeKey === "target-date-month") {
                    const dependency = computedValues["target-date-year"];
                    const allowedMonths =
                      dependency === CURRENT_YEAR.toString()
                        ? MONTHS_VALUES.slice(CURRENT_MONTH)
                        : MONTHS_VALUES;

                    return optionValue === fieldValue || allowedMonths.includes(optionValue);
                  }
                }

                // Need Goal
                if (type === FormTypes.needGoal) {
                  if (
                    nodeKey === "linked-protections-incremental" &&
                    Array.isArray(computedValue)
                  ) {
                    const allowedProtectionsIDs: string[] =
                      protections?.data?.filter((it) => !it.linkedGoal).map((it) => it._id) || [];

                    return (
                      optionValue === fieldValue ||
                      (!computedValue.includes(optionValue) &&
                        allowedProtectionsIDs.includes(optionValue))
                    );
                  }
                }

                return true;
              },
            })
          }
        />
      </AfModal>
      {confirmModal && <ConfirmModal {...confirmModal} />}
    </>
  );
};
