// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Translations
import { getTranslationEntry, lang } from "@lang/index";
// Utils
import { readCurrencyFormatter } from "@utils/read-currency-formatter";
import { readableString } from "@utils/string";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
interface CoverAndPremiumProps {
  premium: NonNullable<Store.Protections.StatePropsDataItem>["premium"];
  premiumPeriodicity: NonNullable<Store.Protections.StatePropsDataItem>["premiumPeriodicity"];
  sumsCovered: NonNullable<Store.Protections.StatePropsDataItem>["sumsCovered"];
}

interface CoverAndPremiumColsProps {
  title: string;
  colSpan: number;
  align: "left" | "right";
}

export const CoverAndPremium = ({
  premium,
  premiumPeriodicity,
  sumsCovered,
}: CoverAndPremiumProps): React.ReactElement => {
  // Columns
  const tableCols: CoverAndPremiumColsProps[] = [
    {
      title: lang("TYPE_TITLE"),
      colSpan: 4,
      align: "left",
    },
    {
      title: lang("VALUE_TITLE"),
      colSpan: 3,
      align: "right",
    },
    {
      title: lang("PAYMENT_FREQUENCY_TITLE"),
      colSpan: 1,
      align: "left",
    },
  ];

  return (
    <>
      <tbody className="covers-table__body">
        <tr>
          {tableCols.map((col, key) => (
            <th key={`${col.title}-${key}`} colSpan={col.colSpan} align={col.align} />
          ))}
        </tr>

        {sumsCovered.map((cover, index) => (
          <tr className="covers-table__row" key={`protections-cover-table-row-${index}`}>
            <td colSpan={4} className="covers-table__col">
              <AfTypography type="body" className="covers-table__col-value">
                {`${lang("COVER_TITLE")} (${readableString(cover.type)})`}
              </AfTypography>
            </td>
            <td colSpan={3} align="right" className="covers-table__col">
              <AfTypography type="body-semi" className="covers-table__col-monetary">
                {readCurrencyFormatter(cover.sumAssured)}
              </AfTypography>
            </td>
            <td colSpan={1} className="covers-table__col">
              <AfTypography type="body" className="covers-table__col-value">
                {cover.periodicity &&
                  lang(
                    getTranslationEntry(`${cover.periodicity?.toUpperCase()}_DESCRIPTIVE_TITLE`)
                  )}
              </AfTypography>
            </td>
          </tr>
        ))}
        <tr className="covers-table__row">
          <td colSpan={4} className="covers-table__col covers-table__col-premium">
            <AfTypography type="body" className="covers-table__col-value">
              {lang("PREMIUM_TITLE")}
            </AfTypography>
          </td>
          <td colSpan={3} align="right" className="covers-table__col covers-table__col-premium">
            <AfTypography type="body-semi" className="covers-table__col-monetary">
              {readCurrencyFormatter(premium)}
            </AfTypography>
          </td>
          <td colSpan={1} className="covers-table__col covers-table__col-premium">
            <AfTypography type="body" className="covers-table__col-value ">
              {lang(getTranslationEntry(`${premiumPeriodicity?.toUpperCase()}_DESCRIPTIVE_TITLE`))}
            </AfTypography>
          </td>
        </tr>
      </tbody>
    </>
  );
};
