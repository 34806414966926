// React
import React, { useCallback, useEffect } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk";
// Types
import { StateProps } from "@redux/rtk/modules/goals";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useClientGroup } from "@hooks/client-group";
import { useIsLoading } from "@hooks/is-loading";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfSpinner } from "@advicefront/ds-spinner";
import { EmptyData } from "@components/empty-data";
import { Section } from "@components/section";

// Props
export interface GoalsProps {
  translations: {
    sectionTitle: string;
    sectionDescription: string;
    sectionActionTitle: string;
    emptyTitle: string;
    emptyActionTitle: string;
  };
  type: FormTypes;
  data?: StateProps["data"];
  children?: React.ReactNode;
}

export const Goals = ({ translations, type, data, children }: GoalsProps): React.ReactElement => {
  // Redux
  const dispatch = Store.useDispatch();
  const { goals } = Store.useSelector();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();
  // Url params
  const [, setUrlParams] = useSearchParams();
  // Loading
  const isLoading = useIsLoading(goals);
  // Handle create goal
  const handleCreateGoal = useCallback(
    (): void =>
      setUrlParams({
        action: FormActions.create,
        type,
      }),
    [setUrlParams, type]
  );

  // Fetch goals
  useEffect(() => {
    if (goals.data || !authToken || !clientGroupId) return;
    // Fetch goals
    void dispatch(
      Store.Goals.fetch({
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  }, [dispatch, clientGroupId, authToken, goals.data]);

  return (
    <Section
      title={translations.sectionTitle}
      description={translations.sectionDescription}
      action={
        !isLoading &&
        !!data?.length && (
          <AfButton
            skin="primary"
            icon={<AfIcon name="basic-add-circle" />}
            onClick={handleCreateGoal}
          >
            {translations.sectionActionTitle}
          </AfButton>
        )
      }
    >
      {isLoading && <AfSpinner className="spinner-centered" />}
      {!isLoading && (
        <>
          {!!data?.length && children}
          {!data?.length && (
            <EmptyData
              title={translations.emptyTitle}
              action={{
                title: translations.emptyActionTitle,
                handler: handleCreateGoal,
              }}
            />
          )}
        </>
      )}
    </Section>
  );
};
