// React
import { useMemo } from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectGoalById } from "@redux/rtk/modules/goals";
// Form Schema
import { FormSchemas } from "@form-schemas/index";
// Types
import { FormModuleProps } from "./";
import { GoalTypes } from "@advicefront/goals-client-axios";
// Type Guards
import { isObjectiveGoalData } from "@utils/type-guards/goals/objective-goal";
// Translations
import { getTranslationEntry, lang } from "@lang/index";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Adapters
import {
  objectiveGoalDataToForm,
  objectiveGoalFormToCreateObjectiveGoalData,
  objectiveGoalFormToUpdateObjectiveGoalData,
} from "@adapters/objective-goals";
// Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useClientGroup } from "@hooks/client-group";
// Context
import { useAppOptions } from "@context/app-options";

export const ObjectiveGoalFormModule = (action: FormActions, id: string): FormModuleProps => {
  // Redux
  const dispatch = Store.useDispatch();
  const { goals } = Store.useSelector();
  //Context
  const { hasOptimalIntegration } = useAppOptions();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();
  // Selected item
  const goalSelected = Store.useSelector(selectGoalById(id));

  // Close modal
  const closeModal = useMemo(
    () => goals.submitSuccess && (action !== FormActions.edit || !hasOptimalIntegration),
    [goals.submitSuccess, hasOptimalIntegration, action]
  );

  // Initial values
  const initialValues = useMemo((): FormModuleProps["initialValues"] => {
    if (!goalSelected || !isObjectiveGoalData(goalSelected)) return undefined;
    return objectiveGoalDataToForm(goalSelected, hasOptimalIntegration);
  }, [goalSelected, hasOptimalIntegration]);

  // Delete modal content
  const deleteModalContent = useMemo(
    (): FormModuleProps["deleteModalContent"] => ({
      title: lang("GOAL_TITLE"),
      name: goalSelected?.name,
      unlinkMessage: {
        description: getTranslationEntry("MULTIPLE_LINK_DESCRIPTION"),
        replacement: [lang("ACCOUNTS_TITLE"), lang("GOAL_TITLE")],
      },
    }),
    [goalSelected]
  );

  // Handle change
  const handleChange: FormModuleProps["handleChange"] = (props) => {
    // Set all fields to be confirm
    const confirmFields = [
      {
        nodeKey: "risk-level",
        result: {
          title: lang("RISK_LEVEL_TITLE"),
          unlinkMessage: {
            description: getTranslationEntry("MULTIPLE_LINK_DESCRIPTION"),
            replacement: [lang("ACCOUNTS_TITLE"), lang("GOAL_TITLE")],
          },
          fieldsToUpdate: {
            "linked-accounts-incremental": [],
          },
        },
        valid:
          Array.isArray(props.data["linked-accounts-incremental"]) &&
          !!props.data["linked-accounts-incremental"].length,
      },
    ];
    // Get field object based on nodeKey
    const field = confirmFields.find((f) => f.nodeKey === props.nodeKey);
    // Return result based on validation
    return field?.valid ? field.result : undefined;
  };

  // Handle create
  const handleCreate: FormModuleProps["handleCreate"] = (data) => {
    // Get objective goal data for submit
    // TODO - Verify on adapter if clientGroupId is valid and remove empty string
    const goalData = objectiveGoalFormToCreateObjectiveGoalData(
      data,
      clientGroupId || "",
      hasOptimalIntegration
    );
    // Handle exception when no objective goal data
    if (!goalData) throw new Error("Unable to create objective goal: Data cannot be undefined");
    // Handle exception authorisation
    if (!authToken || !clientGroupId)
      throw new Error("Unable to create objective goal: Unauthorised");
    // Create objective goal
    void dispatch(
      Store.Goals.create({
        data: goalData,
        goalType: GoalTypes.ObjectiveGoal,
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  };

  // Handle update
  const handleUpdate: FormModuleProps["handleUpdate"] = (data) => {
    // Get objective goal data for submit
    const goalData = objectiveGoalFormToUpdateObjectiveGoalData(data, hasOptimalIntegration);
    // Handle exception when no objective goal data
    if (!goalData) throw new Error("Unable to update objective goal: Data cannot be undefined");
    // Handle exception authorisation
    if (!authToken || !clientGroupId)
      throw new Error("Unable to update objective goal: Unauthorised");
    // Update objective goal
    void dispatch(
      Store.Goals.update({
        id,
        updatedData: goalData,
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  };

  // Handle delete
  const handleDelete: FormModuleProps["handleDelete"] = () => {
    // Handle exception authorisation
    if (!authToken || !clientGroupId)
      throw new Error("Unable to delete objective goal: Unauthorised");
    void dispatch(
      Store.Goals.deleteById({
        id,
        goalType: GoalTypes.ObjectiveGoal,
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  };

  return {
    loading: !!goals.loading,
    closeModal: closeModal,
    formTitle: lang("OBJECTIVE_GOAL_TITLE"),
    formSchema: FormSchemas(action, FormTypes.objectiveGoal),
    initialValues,
    deleteModalContent,
    handleChange: (props) => handleChange(props),
    handleCreate: (data) => handleCreate(data),
    handleUpdate: (data) => handleUpdate(data),
    handleDelete: () => handleDelete(),
  };
};
