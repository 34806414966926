// React
import React, { useCallback, useEffect } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useClientGroup } from "@hooks/client-group";
import { useIsLoading } from "@hooks/is-loading";
// Views
import { ProtectionsTable } from "@views/needs/protections/protections-table";
// Components
import { AfButton } from "@advicefront/ds-button";
import { AfIcon } from "@advicefront/ds-icon";
import { AfSpinner } from "@advicefront/ds-spinner";
import { EmptyData } from "@components/empty-data";
import { Section } from "@components/section";

export const Protections = (): React.ReactElement => {
  // Redux
  const dispatch = Store.useDispatch();
  const { protections } = Store.useSelector();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();
  // Url params
  const [, setUrlParams] = useSearchParams();

  // Loading
  const isLoading = useIsLoading(protections);

  // Handle create protection
  const handleCreateProtection = useCallback(
    (): void =>
      setUrlParams({
        action: FormActions.create,
        type: FormTypes.protection,
      }),
    [setUrlParams]
  );

  // Fetch protections
  useEffect(() => {
    // Stop if no auth, client group or has data
    if (!authToken || !!protections.data || !clientGroupId) return;
    // Fetch protections
    void dispatch(
      Store.Protections.fetch({
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  }, [dispatch, authToken, clientGroupId, protections.data]);

  return (
    <Section
      title={lang("PROTECTIONS_TITLE")}
      action={
        !isLoading &&
        !!protections.data?.length && (
          <AfButton
            skin="primary"
            icon={<AfIcon name="basic-add-circle" />}
            onClick={handleCreateProtection}
          >
            {lang("CREATE_PROTECTION_ACTION")}
          </AfButton>
        )
      }
    >
      {isLoading && <AfSpinner className="spinner-centered" />}
      {!isLoading && (
        <>
          {!!protections.data?.length && <ProtectionsTable />}
          {!protections.data?.length && (
            <EmptyData
              title={lang("NO_PROTECTIONS_TITLE")}
              description={lang("NO_PROTECTIONS_DESCRIPTION")}
              action={{
                title: lang("CREATE_PROTECTION_ACTION"),
                handler: handleCreateProtection,
              }}
            />
          )}
        </>
      )}
    </Section>
  );
};
