// React
import { useCallback } from "react";
// Router
import { generatePath, useNavigate, useParams } from "react-router-dom";
// Context
import { useAppOptions } from "@context/app-options";
// Utils
import {
  useAuthenticated,
  UseAuthenticatedData,
  UseAuthenticatedProps,
} from "@advicefront/fe-infra-auth";
import { getRoute } from "@routes/utils/get-route";

/**
 * Abstracts auth utils handling & also redirects to error views
 * when an error is fired
 * @returns auth data - {@link UseAuthenticatedData}
 */
export const useAuthHandler = (): UseAuthenticatedData => {
  const params = useParams();
  const navigate = useNavigate();

  const { getAuthToken } = useAppOptions();

  const onError = useCallback<UseAuthenticatedProps["onError"]>(() => {
    navigate(generatePath(getRoute("errorAuth"), params), { replace: true });
  }, [navigate, params]);

  const data = useAuthenticated({
    getAuthToken,
    onError,
  });

  return {
    ...data,
    loading: !data.authToken && data.loading, // show only loading if no token was fetched yet
  };
};
