// React
import React from "react";
// Redux
// Hooks
import { useGlobalNotifications } from "@hooks/GlobalNotifications";
// Translations
import { lang } from "@lang/index";
// Layouts
import { LayoutProps } from "@layouts/types";
// Partials
import { GoalsTabs } from "@layouts/goals/goals-tabs";
// Components
import { AfSection } from "@advicefront/ds-grid";
import { AfTypography } from "@advicefront/ds-base";
import { DefaultLayout } from "@layouts/default";

export const GoalsLayout = ({ children }: LayoutProps): React.ReactElement => {
  // Notifications
  useGlobalNotifications();

  return (
    <AfSection>
      <AfTypography type="h1">{lang("GOALS_TITLE")}</AfTypography>
      <GoalsTabs />
      <DefaultLayout>{children}</DefaultLayout>
    </AfSection>
  );
};
