import { useEffect, useState } from "react";
// Types
import { ClientGroupMember } from "@redux/rtk/types";
// Context
import { useAppOptions } from "@context/app-options";

interface ClientGroup {
  clientGroupId: string | null;
  clientGroupMembers?: ClientGroupMember[];
}

export const useClientGroup = (): ClientGroup => {
  const { getClientGroupId, clientGroupMembers } = useAppOptions();
  const [clientGroupId, setClientGroupId] = useState<string | null>(null);
  const [groupMembers, setGroupMembers] = useState<ClientGroupMember[] | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // Reset the error state when dependencies change
    setError(null);

    void (async (): Promise<void> => {
      try {
        const resolvedClientGroupId = await getClientGroupId();
        setClientGroupId(resolvedClientGroupId);
        const resolvedClientGroupMembers = await clientGroupMembers();
        setGroupMembers(resolvedClientGroupMembers);
      } catch (err) {
        setError(new Error("Client information hook failure."));
      }
    })();
  }, [getClientGroupId, clientGroupMembers]);

  if (error) {
    throw error; // This will be caught by the ErrorBoundary
  }
  return { clientGroupId, clientGroupMembers: groupMembers };
};
