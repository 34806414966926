/* eslint-disable @typescript-eslint/consistent-type-assertions */
// Styles
import "./styles/index.scss";
// React
import React, { useCallback } from "react";
// Router
import { useSearchParams } from "react-router-dom";
// Redux
import * as Store from "@redux/rtk/index";
import { selectBackgroundImageByGoalId } from "@redux/rtk/modules/goals";
// Types
import { ResponseNeedGoalDto, ResponseObjectiveGoalDto } from "@advicefront/goals-client-axios";
import { ObjectiveGoalStateItem } from "@redux/rtk/types";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Translations
import { lang } from "@lang/index";
// Components
import { AfIcon, AfIconProps } from "@advicefront/ds-icon";
import { AfAvatarGroup } from "@advicefront/ds-avatar";
import { AfButton } from "@advicefront/ds-button";
import { AfCard } from "@advicefront/ds-card";
import { AfTypography } from "@advicefront/ds-base";
import { LinkBadge } from "@components/link-badge";
//Hooks
import { useOwnershipData } from "@hooks/ownership-data";

// Props
export type GoalCardTypes = FormTypes.objectiveGoal | FormTypes.needGoal;

type GoalCardLinkedItems =
  | ResponseObjectiveGoalDto["linkedAccounts"]
  | ResponseNeedGoalDto["linkedProtections"];

export interface GoalCardProps {
  id: string;
  title?: string;
  type: GoalCardTypes;
  ownership?: ObjectiveGoalStateItem["ownership"];
  details?: Array<{
    icon?: AfIconProps["name"];
    title: string;
    description: string;
  }>;
  requirements?: ResponseNeedGoalDto["requirements"];
  linkedItems: {
    list: GoalCardLinkedItems;
    placeholder: string;
  };
}

export const GoalCard = ({
  id,
  title,
  type,
  ownership,
  details,
  requirements,
  linkedItems,
}: GoalCardProps): React.ReactElement => {
  // Redux
  const bgImage = Store.useSelector(selectBackgroundImageByGoalId(id));

  const ownershipData = useOwnershipData(ownership);
  // Avatars
  const avatarData = ownershipData?.image?.map((it) => ({
    name: it?.name?.trim(),
    nativeImgProps: {
      src: it?.image,
    },
  }));

  // Url params
  const [, setUrlParams] = useSearchParams();

  // Background image
  const styles = {
    [`--background-image`]: `url(${bgImage?.imageUrl})`,
  } as React.CSSProperties;

  // Redirect to edit goal link
  const redirectToEditGoal = useCallback(
    (): void =>
      setUrlParams({
        action: FormActions.edit,
        type,
        id,
      }),
    [setUrlParams, id, type]
  );

  return (
    <div className="af-goal-card">
      <AfCard variation={["padded"]}>
        <AfCard.Header>
          <div className="af-goal-card__header" style={styles}>
            <AfButton
              className="af-goal-card__header-action"
              skin="secondary"
              size="s"
              icon={<AfIcon name="content-edit" />}
              onClick={redirectToEditGoal}
            >
              {lang("OPEN_GOAL_ACTION")}
            </AfButton>
          </div>
        </AfCard.Header>
        <div className="af-goal-card__body">
          <div className="af-goal-card__content">
            <AfTypography type="h3" className="af-goal-card__content-title">
              {title}
            </AfTypography>
            {ownership && avatarData && <AfAvatarGroup avatars={avatarData} />}
          </div>
          {details && (
            <div className="af-goal-card__details">
              {details.map((detail, key) => (
                <div key={`detail-${id}-${key}`} className="af-goal-card__details-item">
                  {detail.icon && <AfIcon name={`${detail.icon}`} />}
                  <AfTypography type="extra-small" className="af-goal-card__details-title">
                    {detail.title}:
                  </AfTypography>
                  <AfTypography
                    type="extra-small-bold"
                    className="af-goal-card__details-description"
                  >
                    {detail.description}
                  </AfTypography>
                </div>
              ))}
            </div>
          )}
          {requirements && (
            <AfTypography type="small" className="af-goal-card__requirements">
              {requirements}
            </AfTypography>
          )}
          {linkedItems && (
            <div className="af-goal-card__linked-items">
              {linkedItems.list?.length ? (
                <div className="af-goal-card__linked-items-list">
                  {linkedItems.list.map((item) => (
                    <LinkBadge key={item._id} goalType={type} itemId={item._id} />
                  ))}
                </div>
              ) : (
                <AfTypography type="small" className="af-goal-card__linked-items-empty">
                  {linkedItems.placeholder}
                </AfTypography>
              )}
            </div>
          )}
        </div>
      </AfCard>
    </div>
  );
};
