/* eslint-disable @typescript-eslint/consistent-type-assertions */
// Types
import {
  CreateInvestmentAccountDto,
  Ownership,
  Periodicity,
  ResponseInvestmentAccountDto,
  SourceAmount,
  TransferType,
  UpdateInvestmentAccountDto,
  WrapperType,
} from "@advicefront/goals-client-axios";
import { FormSchemaRenderer } from "@advicefront/fe-infra-form-schema";
// Utils
import {
  isAccountData,
  isCreateAccountData,
  isUpdateAccountData,
} from "@utils/type-guards/accounts";
import { isObjectArrayComputedValue } from "@form-schemas/utils/is-computed-value-types";

/**
 * Transform function from Form Schema data to Account Data
 * @param data - account data from Form
 * @param id -  account id
 * @returns account data in account state data object or undefined
 */

export const accountFormToAccountData = (
  data: FormSchemaRenderer.ComputedValuesState | undefined,
  id?: string
): ResponseInvestmentAccountDto | undefined => {
  if (!data) return;
  if (isAccountData(data)) {
    return data;
  }
  if (!isAccountData(data)) {
    const isInitialDepositDefined =
      typeof data["initial-deposit-value"] === "string" &&
      typeof data["initial-deposit-source"] === "string" &&
      (!!data["initial-deposit-value"].length || !!data["initial-deposit-source"].length);

    return {
      clientGroupId: "",
      _id: id ? id : "",
      createdAt: "",
      updatedAt: "",
      ownership: data["ownership"]
        ? (data["ownership"] as string[]).length > 1
          ? Ownership.Joint
          : ((data["ownership"] as string[])[0] as Ownership)
        : Ownership.Owner,
      name: data["name"] as string,
      linkedGoals: [],
      portfolioGroupId: "",
      riskLevel: data["account-risk-level"] as string,
      wrapperType: data["account-wrapper-type"] as WrapperType,
      portfolio: isObjectArrayComputedValue(data["portfolioAllocation"])
        ? data["portfolioAllocation"].map((portfolio) => ({
            portfolioId: portfolio["portfolio-id"],
            allocation: portfolio["allocation"],
          }))
        : [],
      initialDeposit: isInitialDepositDefined
        ? {
            value: data["initial-deposit-value"] as string,
            source: data["initial-deposit-source"] as string,
          }
        : null,
      transferFunds:
        isObjectArrayComputedValue(data["transfer-funds"]) && !!data["transfer-funds"].length
          ? data["transfer-funds"].map((transfer) => ({
              source: transfer["transfer-fund-source-name"],
              value: transfer["transfer-fund-value"],
              providerName: transfer["transfer-fund-provider"],
              sourceId: transfer["transfer-fund-source-id"],
              transferType: transfer["transfer-type"] as TransferType,
              sourceAmount: transfer["transfer-fund-source-amount"] as SourceAmount,
            }))
          : null,
      regularContributions:
        isObjectArrayComputedValue(data["regular-contribution"]) &&
        !!data["regular-contribution"].length
          ? data["regular-contribution"].map((contribution) => ({
              value: contribution["regular-contribution-value"],
              source: contribution["contributionSource"],
              frequency: contribution["contribution-frequency"] as Periodicity,
            }))
          : null,
      regularContributionTotal: "",
      transferFundTotal: "",
    };
  }
};

/**
 * Transform function from account state data to Form Schema type
 * @param data - account data from Form
 * @param id - account id
 * @returns account data in Form Schema object or undefined
 */

export const accountDataToForm = (
  data: ResponseInvestmentAccountDto
): FormSchemaRenderer.ComputedValuesState => ({
  ["name"]: data.name,
  ["account-wrapper-type"]: data.wrapperType,
  ["ownership"]: data.ownership === "Joint" ? ["Owner", "Partner"] : [data.ownership],
  ["account-risk-level"]: data.riskLevel,
  ["provider-id"]: data.portfolioGroupId,
  ["initial-deposit-value"]: data.initialDeposit?.value.toString(),
  ["initial-deposit-source"]: data.initialDeposit?.source,
  ["regular-contribution"]: data.regularContributions?.map((payment) => ({
    ["regular-contribution-value"]: payment.value.toString(),
    ["contribution-frequency"]: payment.frequency,
    ["contributionSource"]: payment.source,
  })),
  ["transfer-funds"]: data.transferFunds?.map((transfer) => ({
    ["transfer-fund-value"]: transfer.value.toString(),
    ["transfer-fund-source-id"]: transfer.sourceId,
    ["transfer-fund-source-name"]: transfer.source,
    ["transfer-fund-provider"]: transfer.providerName,
    ["transfer-type"]: transfer.transferType,
    ["transfer-fund-source-amount"]: transfer.sourceAmount,
  })),
  ["portfolioAllocation"]: data.portfolio.map((portfolio) => ({
    ["portfolio-id"]: portfolio.portfolioId,
    ["allocation"]: (Number(portfolio.allocation) * 100).toString(),
  })),
});

/**
 * Transform function from Form Schema inputs to Account Data type for creating Account
 * @param data - account data from Form
 * @param id - account id
 * @returns account data in create account type
 */

export const accountFormToCreateAccountData = (
  data: FormSchemaRenderer.ComputedValuesState | CreateInvestmentAccountDto,
  clientGroupId: string
): CreateInvestmentAccountDto | undefined => {
  if (!data) return;
  if (isCreateAccountData(data)) {
    return data;
  }
  if (!isAccountData(data)) {
    const isInitialDepositDefined =
      typeof data["initial-deposit-value"] === "string" &&
      typeof data["initial-deposit-source"] === "string" &&
      (!!data["initial-deposit-value"].length || !!data["initial-deposit-source"].length);

    return {
      clientGroupId: clientGroupId,
      ownership:
        (data["ownership"] as string[]).length > 1
          ? Ownership.Joint
          : ((data["ownership"] as string[])[0] as Ownership),
      name: data["name"] as string,
      linkedGoals: [],
      portfolioGroupId: data["provider-id"] as string,
      riskLevel: data["account-risk-level"] as string,
      wrapperType: data["account-wrapper-type"] as WrapperType,
      portfolio: isObjectArrayComputedValue(data["portfolioAllocation"])
        ? data["portfolioAllocation"].map((portfolio) => ({
            portfolioId: portfolio["portfolio-id"],
            allocation: (Number(portfolio["allocation"]) / 100).toString(),
          }))
        : [],
      initialDeposit: isInitialDepositDefined
        ? {
            value: data["initial-deposit-value"] as string,
            source: data["initial-deposit-source"] as string,
          }
        : null,
      transferFunds:
        isObjectArrayComputedValue(data["transfer-funds"]) && !!data["transfer-funds"].length
          ? data["transfer-funds"].map((transfer) => ({
              source: transfer["transfer-fund-source-name"],
              value: transfer["transfer-fund-value"],
              providerName: transfer["transfer-fund-provider"],
              sourceId: transfer["transfer-fund-source-id"],
              transferType: transfer["transfer-type"] as TransferType,
              sourceAmount: transfer["transfer-fund-source-amount"] as SourceAmount,
            }))
          : null,
      regularContributions:
        isObjectArrayComputedValue(data["regular-contribution"]) &&
        !!data["regular-contribution"].length
          ? data["regular-contribution"].map((contribution) => ({
              value: contribution["regular-contribution-value"],
              source: contribution["contributionSource"],
              frequency: contribution["contribution-frequency"] as Periodicity,
            }))
          : null,
    };
  }
};

/**
 * Transform function from Form Schema inputs to Account Data type for updating Account
 * @param data - account data from Form
 * @param id - account id
 * @returns account data in update account type
 */

export const accountFormToUpdateAccountData = (
  data: FormSchemaRenderer.ComputedValuesState | UpdateInvestmentAccountDto
): UpdateInvestmentAccountDto | undefined => {
  if (!data) return;
  if (isUpdateAccountData(data)) {
    return data;
  }
  if (!isAccountData(data)) {
    const isInitialDepositDefined =
      typeof data["initial-deposit-value"] === "string" &&
      typeof data["initial-deposit-source"] === "string" &&
      (!!data["initial-deposit-value"].length || !!data["initial-deposit-source"].length);

    return {
      ownership:
        (data["ownership"] as string[]).length > 1
          ? Ownership.Joint
          : ((data["ownership"] as string[])[0] as Ownership),
      name: data["name"] as string,
      portfolioGroupId: data["portfolio-id"] as string,
      riskLevel: data["account-risk-level"] as string,
      wrapperType: data["account-wrapper-type"] as WrapperType,
      portfolio: isObjectArrayComputedValue(data["portfolioAllocation"])
        ? data["portfolioAllocation"].map((portfolio) => ({
            portfolioId: portfolio["portfolio-id"],
            allocation: (Number(portfolio["allocation"]) / 100).toString(),
          }))
        : [],
      initialDeposit: isInitialDepositDefined
        ? {
            value: data["initial-deposit-value"] as string,
            source: data["initial-deposit-source"] as string,
          }
        : null,
      transferFunds:
        isObjectArrayComputedValue(data["transfer-funds"]) && !!data["transfer-funds"].length
          ? data["transfer-funds"].map((transfer) => ({
              source: transfer["transfer-fund-source-name"],
              value: transfer["transfer-fund-value"],
              providerName: transfer["transfer-fund-provider"],
              sourceId: transfer["transfer-fund-source-id"],
              transferType: transfer["transfer-type"] as TransferType,
              sourceAmount: transfer["transfer-fund-source-amount"] as SourceAmount,
            }))
          : null,
      regularContributions:
        isObjectArrayComputedValue(data["regular-contribution"]) &&
        !!data["regular-contribution"].length
          ? data["regular-contribution"].map((contribution) => ({
              value: contribution["regular-contribution-value"],
              source: contribution["contributionSource"],
              frequency: contribution["contribution-frequency"] as Periodicity,
            }))
          : null,
    };
  }
};
