// React
import { createRoot, hydrateRoot } from "react-dom/client";
import React from "react";
// Config
import * as appConfig from "./config-client";
// App
import { App } from "./App";
// Package info
import packageJson from "../package.json";

const container =
  document.getElementById(appConfig.ROOT_ELEMENT_ID) ||
  ((): HTMLElement => {
    throw new Error(`${packageJson.name} - Missing root element (#${appConfig.ROOT_ELEMENT_ID})`);
  })();

if (container.hasChildNodes()) {
  hydrateRoot(container, <App />);
} else {
  createRoot(container).render(<App />);
}
