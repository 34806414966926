// Translations
import { allTranslations, getTranslationEntry, lang } from "@lang/index";

/**
 * Months
 * @example CURRENT_MONTH: 7
 * @example MONTHS: [`{name: March, number: 7, value: "07"}`]
 * @example MONTHS_VALUES: ["01", "02", "03"]
 */

// Months Props
interface MonthsProps {
  name: string;
  number: number;
  value: string;
}

// Get current month (+1) since starts from 0
export const CURRENT_MONTH = new Date().getMonth() + 1;

// Months names from translations
const monthsTranslations = Object.keys(allTranslations).filter((key) =>
  key.includes("_CALENDAR_MONTH_TITLE")
);

// Map all months with name, number and value leading with 0
export const MONTHS: MonthsProps[] = monthsTranslations.map((k, i) => ({
  name: lang(getTranslationEntry(k)),
  number: ++i,
  value: i < 10 ? `0${i}` : `${i}`,
}));

// Get all months values
export const MONTHS_VALUES = MONTHS.map((month) => month.value);

/**
 * Years
 * @example CURRENT_YEAR: 2022
 * @example MAX_YEAR: 2072
 * @example YEARS: ["2022", "2023", "2024"]
 */

// Get current year
export const CURRENT_YEAR = new Date().getFullYear();

// Get max year
export const MAX_YEAR = CURRENT_YEAR + 50;

// Map years from current year until max year
export const YEARS = Array.from({ length: MAX_YEAR - CURRENT_YEAR + 1 }, (_, i) =>
  (i + CURRENT_YEAR).toString()
);
