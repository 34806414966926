// React
import { useEffect, useState } from "react";
// Redux
import * as Store from "@redux/rtk";
import { ClientGroupMember, ObjectiveGoalStateItem } from "@redux/rtk/types";
//Hooks
import { useClientGroup } from "@hooks/client-group";

interface MemberOwnershipData {
  type?: string;
  image?: { name?: string; image?: string }[];
  firstName?: string;
}

export const useOwnershipData = (
  dataOwnership?:
    | NonNullable<Store.Accounts.StatePropsDataItem>["ownership"]
    | ObjectiveGoalStateItem["ownership"]
): MemberOwnershipData | undefined => {
  const { clientGroupMembers: resolvedClientGroupMembers } = useClientGroup();
  const [groupMembers, setGroupMembers] = useState<MemberOwnershipData | undefined>(undefined);

  useEffect(() => {
    const member: ClientGroupMember | undefined = resolvedClientGroupMembers?.find(
      (owner) => dataOwnership === owner.type
    );
    const jointNames: string | undefined = resolvedClientGroupMembers
      ?.map((it) => it.firstName)
      .join(", ");

    // Ownership Type
    const type = dataOwnership;

    // FirstName
    const firstName = dataOwnership === "Joint" ? jointNames : member?.firstName;

    // Avatars
    const image =
      dataOwnership === "Joint"
        ? resolvedClientGroupMembers?.map((it) => ({
            name: it.fullName,
            image: it.image,
          }))
        : [
            {
              name: member?.fullName,
              image: member?.image,
            },
          ];
    setGroupMembers({ type, firstName, image });
  }, [resolvedClientGroupMembers, dataOwnership]);

  return groupMembers;
};
