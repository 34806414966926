// React
import { useMemo } from "react";
// Redux
import * as Store from "@redux/rtk";
import { selectAccountById } from "@redux/rtk/modules/accounts";
// Form Schema
import { FormSchemas } from "@form-schemas/index";
// Types
import { FormModuleProps } from "./";
// Translations
import { getTranslationEntry, lang } from "@lang/index";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Adapters
import {
  accountDataToForm,
  accountFormToCreateAccountData,
  accountFormToUpdateAccountData,
} from "@adapters/accounts";
//Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useClientGroup } from "@hooks/client-group";

export const AccountFormModule = (action: FormActions, id: string): FormModuleProps => {
  // Redux
  const dispatch = Store.useDispatch();
  const { accounts } = Store.useSelector();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();
  // Selected item
  const accountSelected = Store.useSelector(selectAccountById(id));

  // Initial values
  const initialValues = useMemo(
    (): FormModuleProps["initialValues"] => accountSelected && accountDataToForm(accountSelected),
    [accountSelected]
  );

  // Delete modal content
  const deleteModalContent = useMemo(
    (): FormModuleProps["deleteModalContent"] => ({
      title: lang("ACCOUNT_TITLE"),
      name: accountSelected?.name,
      unlinkMessage: {
        description: "MULTIPLE_LINK_DESCRIPTION",
        replacement: [lang("GOALS_TITLE"), lang("ACCOUNT_TITLE")],
      },
    }),
    [accountSelected]
  );

  // Handle change
  const handleChange: FormModuleProps["handleChange"] = (props) => {
    // Set all fields to be confirm
    const confirmFields = [
      {
        nodeKey: "account-risk-level",
        result: {
          title: lang("RISK_LEVEL_TITLE"),
          unlinkMessage: {
            description: getTranslationEntry("MULTIPLE_LINK_DESCRIPTION"),
            replacement: [lang("PORTFOLIOS_TITLE"), lang("ACCOUNT_TITLE")],
          },
          fieldsToUpdate: {
            portfolioAllocation: [],
          },
        },
        valid:
          Array.isArray(props.data["portfolioAllocation"]) &&
          !!props.data["portfolioAllocation"].length,
      },
    ];
    // Get field object based on nodeKey
    const field = confirmFields.find((f) => f.nodeKey === props.nodeKey);
    // Return result based on validation
    return field?.valid ? field.result : undefined;
  };

  // Handle create
  const handleCreate: FormModuleProps["handleCreate"] = (data) => {
    // Handle exception authorisation
    if (!authToken || !clientGroupId) throw new Error("Unable to create account: Unauthorised");
    // Get account data for submit
    const accountData = accountFormToCreateAccountData(data, clientGroupId);
    // Handle exception when no account data
    if (!accountData) throw new Error("Unable to create account: Data cannot be undefined");
    // Create account
    void dispatch(
      Store.Accounts.create({
        data: accountData,
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  };

  // Handle update
  const handleUpdate: FormModuleProps["handleUpdate"] = (data) => {
    // Handle exception authorisation
    if (!authToken || !clientGroupId) throw new Error("Unable to update account: Unauthorised");
    // Get account data for submit
    const accountData = accountFormToUpdateAccountData(data);
    // Handle exception when no account data
    if (!accountData) throw new Error("Unable to update account: Data cannot be undefined");
    // Update account
    void dispatch(
      Store.Accounts.updateById({
        id,
        updatedData: accountData,
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  };

  // Handle delete
  const handleDelete: FormModuleProps["handleDelete"] = () => {
    // Handle exception authorisation
    if (!authToken || !clientGroupId) throw new Error("Unable to delete account: Unauthorised");
    void dispatch(
      Store.Accounts.deleteById({
        id,
        authToken: authToken,
        clientGroupId: clientGroupId,
      })
    );
  };

  return {
    loading: !!accounts.loading,
    closeModal: accounts.submitSuccess,
    formTitle: lang("ACCOUNT_TITLE"),
    formSchema: FormSchemas(action, FormTypes.account),
    initialValues,
    deleteModalContent,
    handleChange: (props) => handleChange(props),
    handleCreate: (data) => handleCreate(data),
    handleUpdate: (data) => handleUpdate(data),
    handleDelete: () => handleDelete(),
  };
};
