// Styles
import "./styles/index.scss";
// React
import React, { useCallback } from "react";
// Redux
import * as Store from "@redux/rtk";
// Translations
import { lang } from "@lang/index";
// Utils
import { isObjectiveGoalData } from "@utils/type-guards/goals/objective-goal";
// Types
import { ObjectiveGoalStateItem } from "@redux/rtk/types";
// Components
import { AfCol, AfRow } from "@advicefront/ds-grid";
import { AfAlert } from "@advicefront/ds-alert";
import { AfButton } from "@advicefront/ds-button";
import { AfEmptyState } from "@advicefront/ds-empty-state";
import { AfIcon } from "@advicefront/ds-icon";
import { AfTypography } from "@advicefront/ds-base";
import { GoalProjectionCharts } from "./projection-charts";
import { GoalProjectionSummary } from "./projection-summary";
//Hooks
import { useAuthHandler } from "@hooks/auth-handler";
import { useClientGroup } from "@hooks/client-group";

// Props
interface ProjectionsProps {
  goal: ObjectiveGoalStateItem;
}

export const Projections = ({ goal }: ProjectionsProps): React.ReactElement => {
  // Redux
  const dispatch = Store.useDispatch();
  // ClientGroup Hook
  const { clientGroupId } = useClientGroup();
  //Auth
  const { authToken } = useAuthHandler();

  // Update projection
  const updateProjection = useCallback((): void => {
    // Handle exception authorisation
    if (!authToken || !clientGroupId) throw new Error("Unable to update Projection: Unauthorised");
    void dispatch(
      Store.Goals.createProjectionForId({
        authToken: authToken,
        id: goal._id,
        clientGroupId: clientGroupId,
      })
    );
  }, [dispatch, goal, authToken, clientGroupId]);

  // TODO: add missing conditions after projection integration
  return (
    <>
      {isObjectiveGoalData(goal) && !goal.linkedAccounts?.length && (
        <div className="empty-projection">
          <AfEmptyState heading={lang("PROJECTIONS_TITLE")}>
            {lang("PROJECTIONS_EMPTY_STATE_DESCRIPTION")}
          </AfEmptyState>
        </div>
      )}
      {isObjectiveGoalData(goal) && !!goal.linkedAccounts?.length && (
        <div className="projection-section">
          <AfRow justify="spread" orientation="vertical" spacing="md">
            {!!goal.projection && !goal.projection?.status.isValid && (
              <AfCol>
                <div className="update-banner">
                  <AfAlert
                    description={
                      <div className="update-banner__description">
                        <AfTypography>{lang("REFRESH_PROJECTION_DESCRIPTION")}</AfTypography>
                        <AfButton
                          skin="primary"
                          type="button"
                          icon={<AfIcon name="arrow-repeat-circle" />}
                          onClick={updateProjection}
                        >
                          {lang("REFRESH_ACTION")}
                        </AfButton>
                      </div>
                    }
                    skin="warning"
                  />
                </div>
              </AfCol>
            )}
            <AfCol>
              <GoalProjectionSummary
                inflationRate={goal.inflationRate}
                targetDate={goal.endDate}
                targetAmount={goal.targetValue}
              />
            </AfCol>
            {/* Display button to trigger charts when there is no projections yet */}
            {!goal.projection && (
              <AfCol>
                <AfButton skin="primary" type="button" onClick={updateProjection}>
                  {lang("SEE_PROJECTIONS_ACTION")}
                </AfButton>
              </AfCol>
            )}
            {!!goal.projection && (
              <AfCol>
                <GoalProjectionCharts
                  targetAmount={goal.targetValue}
                  projection={goal.projection}
                />
              </AfCol>
            )}
          </AfRow>
        </div>
      )}
    </>
  );
};
