// React
import { useMemo } from "react";
// Redux
import * as Store from "@redux/rtk";
// Form Schema
import { FormSchema } from "@advicefront/fe-infra-form-schema";
// Constants
import { FormActions, FormTypes } from "@constants/index";
// Schemas
import { accountsFormSchema } from "./accounts";
import { needGoalFormSchema } from "./need-goal";
import { objectiveGoalFormSchema } from "./objective-goal";
import { protectionFormSchema } from "./protection";
//Context
import { useAppOptions } from "@context/app-options";
//Types
import { ClientGroupMember } from "@redux/rtk/types";
//Hooks
import { useClientGroup } from "@hooks/client-group";

// Props
interface FormSchemasStoreData {
  goals?: Store.Goals.StateProps["data"];
  portfolios?: Store.Portfolios.StateProps["data"];
  accounts?: Store.Accounts.StateProps["data"];
  protections?: Store.Protections.StateProps["data"];
  clientGroup?: ClientGroupMember[];
  hasOptimalIntegration: boolean;
}

export interface FormSchemasProps {
  action: FormActions;
  storeData: FormSchemasStoreData;
}

export const FormSchemas = (action: FormActions, type: FormTypes): FormSchema.FormSchema => {
  // Redux
  const { goals, portfolios, accounts, protections } = Store.useSelector();
  //Context
  const { hasOptimalIntegration } = useAppOptions();
  // ClientGroup Hook
  const { clientGroupMembers } = useClientGroup();

  // Set props for form schemas
  const props = useMemo<FormSchemasProps>(
    () => ({
      action,
      storeData: {
        goals: goals.data,
        portfolios: portfolios.data,
        accounts: accounts.data,
        protections: protections.data,
        clientGroup: clientGroupMembers,
        hasOptimalIntegration: hasOptimalIntegration,
      },
    }),
    [
      action,
      goals.data,
      clientGroupMembers,
      portfolios.data,
      accounts.data,
      protections.data,
      hasOptimalIntegration,
    ]
  );

  return useMemo(() => {
    switch (type) {
      // Account
      case FormTypes.account:
        return accountsFormSchema(props);
      // Need Goal
      case FormTypes.needGoal:
        return needGoalFormSchema(props);
      // Objective Goal
      case FormTypes.objectiveGoal:
        return objectiveGoalFormSchema(props);
      // Protection
      case FormTypes.protection:
        return protectionFormSchema(props);
      // Throw error
      default:
        throw new Error(`Form type not supported - "${type}"`);
    }
  }, [type, props]);
};
