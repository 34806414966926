// React
import React from "react";
// Redux
import * as Store from "@redux/rtk/";
// Translations
import { getTranslationEntry, lang } from "@lang/index";
// Utils
import { readCurrencyFormatter } from "@utils/read-currency-formatter";
// Components
import { AfTypography } from "@advicefront/ds-base";

// Props
interface ContributionsProps {
  transactions: NonNullable<Store.Accounts.StatePropsDataItem>["regularContributions"];
  total: NonNullable<Store.Accounts.StatePropsDataItem>["regularContributionTotal"];
}

interface ContributionsColsProps {
  title: string;
  colSpan: number;
  align: "left" | "right";
}

export const Contributions = ({ transactions, total }: ContributionsProps): React.ReactElement => {
  // Columns
  const contributionsCols: ContributionsColsProps[] = [
    {
      title: lang("CONTRIBUTIONS_TITLE"),
      colSpan: 4,
      align: "left",
    },
    {
      title: lang("CONTRIBUTION_AMOUNT_TITLE"),
      colSpan: 4,
      align: "right",
    },
  ];

  return (
    <>
      {transactions && (
        <tbody className="transactions-table__body">
          <tr className="transactions-table__row">
            {contributionsCols.map((col, key) => (
              <th
                key={`${col.title}-${key}`}
                colSpan={col.colSpan}
                align={col.align}
                className="transactions-table__col"
              >
                <AfTypography type="small" className="transactions-table__col-legend">
                  {col.title}
                </AfTypography>
              </th>
            ))}
          </tr>
          {transactions.map((contribution, key) => (
            <tr key={`${contribution.source}-${key}`} className="transactions-table__row">
              <td colSpan={4} className="transactions-table__col">
                <AfTypography type="body" className="transactions-table__col-value">
                  {contribution.source}
                </AfTypography>
              </td>
              <td colSpan={4} align="right" className="transactions-table__col">
                <AfTypography type="body" className="transactions-table__col-value">
                  {`${readCurrencyFormatter(contribution.value)} ${lang(
                    getTranslationEntry(`${contribution.frequency.toUpperCase()}_DESCRIPTIVE_TITLE`)
                  )}`}
                </AfTypography>
              </td>
            </tr>
          ))}
          <tr className="transactions-table__row">
            <td colSpan={8} align="right" className="transactions-table__col">
              <AfTypography type="body-semi" className="transactions-table__col-total">
                {readCurrencyFormatter(total)} {lang("YEARLY_DESCRIPTIVE_TITLE")}
              </AfTypography>
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};
