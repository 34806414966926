// React
import React from "react";
// Translations
import { Navigate, RouteProps } from "react-router-dom";
// Utils
import { requiredRoutePath } from "@routes/utils/required-route-path";
// Views
import * as Views from "@views/index";

// DOCS: https://beta.reactrouter.com/en/v6.3.0/upgrading/v5#upgrade-all-switch-elements-to-routes

export interface View {
  // Route name / title
  name: string;
  // https://reactrouter.com/web/example/basic
  options: RouteProps;
}

const redirects = {
  index: {
    name: "Index",
    options: {
      path: "/",
      element: <Navigate replace to={requiredRoutePath(Views.objectives)} />,
    },
  },
};

export const allRoutes = {
  ...redirects,
  ...Views,
};

export type RouteIndex = keyof typeof allRoutes;

// Generate routes list from grouped imports object
export const routes: View[] = Object.values(allRoutes);
