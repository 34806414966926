/**
 * App (entry) options, options with no visual/UI
 * responsibility should be defined here.
 * (MFE) init options of the same scope should be included here
 */

// React
import React, { useContext } from "react";
// Utils
import { UseAuthenticatedProps } from "@advicefront/fe-infra-auth";
// Types
import { ClientGroupMember } from "@redux/rtk/types";

export interface AppOptionsContextProps {
  goalsApiHost?: string;
  routerBaseName?: string;
  getAuthToken: UseAuthenticatedProps["getAuthToken"];
  getClientGroupId: () => Promise<string | null>;
  hasOptimalIntegration: boolean;
  clientGroupMembers: () => Promise<ClientGroupMember[] | undefined>;
}

export const AppOptionsContextDefaults: AppOptionsContextProps = {
  goalsApiHost: undefined,
  routerBaseName: undefined,
  getAuthToken: () => Promise.resolve(undefined),
  getClientGroupId: () => Promise.resolve(null),
  hasOptimalIntegration: false,
  clientGroupMembers: () => Promise.resolve(undefined),
};

export const AppOptionsContext =
  React.createContext<AppOptionsContextProps>(AppOptionsContextDefaults);

export const useAppOptions = (): AppOptionsContextProps => useContext(AppOptionsContext);
